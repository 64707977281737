import { DayOfTheWeek } from '../../../constants/enums';
import {
    HandleMouseDownFn,
    HandleMouseEnterCellFn,
    HandleTouchMoveFn,
} from '../calendar.constants';

type EmptyCellProps = {
    day: DayOfTheWeek;
    timeSlotIndex: number;
    handleMouseEnterCell: HandleMouseEnterCellFn;
    handleMouseDown: HandleMouseDownFn;
    handleTouchStart: HandleMouseDownFn;
};

export function EmptyCell({
    day,
    timeSlotIndex,
    handleMouseDown,
    handleMouseEnterCell,
    handleTouchStart,
}: EmptyCellProps) {
    return (
        <div
            data-calendar={`${day}-${timeSlotIndex}`}
            className="calendar-cell"
            onMouseEnter={() => handleMouseEnterCell(day, timeSlotIndex)}
            onMouseDown={() => handleMouseDown(day, timeSlotIndex)}
            onTouchStart={() => handleTouchStart(day, timeSlotIndex)}
        >
            {/* {(timeSlotIndex * 15) / 60} */}
        </div>
    );
}
