import { useState } from 'react';
import { useSelector } from '../../hooks/use-selector.hook';
import { useNavigate, useParams } from 'react-router';
import { navigateToCreatePlace } from '../../utils/route.utils';
import { Location } from './components/location';
import { NoPlaces } from './components/no-places';
import { Places } from './components/places/places';
import { Button, ButtonClassName } from '../../components/button/button';
import { useFetchPlaces, usePlacesReroute } from './hooks/places.hook';
import { Activity } from '../../constants/enums';
import { LoadingEntities } from '../../components/loading-entities/loading-entities';
import { Input } from '../../components/input';
import { useQueryCoords } from '../../hooks/use-query-coords.hook';
import { View } from '../../components/view';
import { PLACES_ROUTE } from '../../constants/routes';
import { toTitleCase } from '../../utils/string.util';

export function PlacesView() {
    const maximumDistance = useSelector((state) => state.localPlayStore.maximumDistance);
    const navigate = useNavigate();

    const { latitude, longitude } = useQueryCoords();

    const [searchText, setSearchText] = useState('');

    const { activity } = useParams() as { activity: Activity };

    usePlacesReroute(navigate, activity, latitude, longitude);

    const { places, isLoading: isLoadingPlaces } = useFetchPlaces({
        latitude,
        activity,
        longitude,
        maximumDistance,
        userCoords: { latitude, longitude },
    });

    function handleAddAPlacePressed() {
        navigateToCreatePlace(navigate, activity, { latitude, longitude });
    }

    return (
        <View route={PLACES_ROUTE} title={`Nearby ${toTitleCase(activity)}`}>
            <div className="flex w-full flex-col">
                <div className="mb-4 flex w-full flex-row items-center border-b-1 pb-4">
                    <div className="flex w-1/3 flex-row justify-start xs:hidden sm:block">
                        <Location activity={activity} latitude={latitude} longitude={longitude} />
                    </div>
                    <div className="flex w-1/2 sm:w-1/3">
                        <Input placeholder="Search Results" handleOnChange={setSearchText} />
                    </div>
                    <div className="flex w-1/2 flex-row justify-end sm:w-1/3">
                        {/* <MaximumDistance /> */}
                        {places.length > 0 && (
                            <Button
                                className={ButtonClassName.Secondary}
                                onClick={handleAddAPlacePressed}
                            >
                                Add Place
                            </Button>
                        )}
                    </div>
                </div>
                {isLoadingPlaces ? (
                    <LoadingEntities label="Loading Nearby Places" />
                ) : (
                    <Places
                        longitude={longitude}
                        latitude={latitude}
                        places={places}
                        searchText={searchText}
                    />
                )}
            </div>
        </View>
    );
}
