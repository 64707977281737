import { FaDiscord, FaPatreon } from 'react-icons/fa';
import { HeaderIcon } from './header-icon';
import { DISCORD_ROUTE, PATREON_ROUTE } from '../../../constants/routes';

const ICON_SIZE = 24;

export function RightSideActions() {
    return (
        <div className="flow-row h-full w-1/2 justify-end xs:hidden sm:flex">
            <HeaderIcon url={DISCORD_ROUTE} icon={<FaDiscord size={ICON_SIZE} />} />
            <HeaderIcon url={PATREON_ROUTE} icon={<FaPatreon size={ICON_SIZE} />} />
        </div>
    );
}
