import { FaX } from 'react-icons/fa6';
import { OpenPlayTimesDTO } from '../../../constants/entities.types';
import { DayOfTheWeek } from '../../../constants/enums';
import { convertMinutesToTime, timeToMinutes } from '../../../utils/date.util';
import { HandleTimeRemoveFn, TIME_SLOT_HEIGHT, TIME_SLOT_MINUTES } from '../calendar.constants';

type TimeSlotProps = {
    day: DayOfTheWeek;
    openPlayTimesDTO: OpenPlayTimesDTO;
    timeSlotIndex: number;
    draggingDay: DayOfTheWeek;
    handleTimeRemove: HandleTimeRemoveFn;
    isEdit: boolean;
};

export function TimeSlot({
    day,
    timeSlotIndex,
    openPlayTimesDTO,
    draggingDay,
    handleTimeRemove,
    isEdit,
}: TimeSlotProps) {
    const openPlayTimes = openPlayTimesDTO[day];

    if (!openPlayTimes) {
        return undefined;
    }

    const timeSlotMinute = TIME_SLOT_MINUTES[timeSlotIndex];

    return (
        <>
            {openPlayTimes.map((openPlayTime, index) => {
                const { startTime, endTime } = openPlayTime;
                const startTimeMinute = timeToMinutes(startTime);

                if (timeSlotMinute !== startTimeMinute) {
                    return undefined;
                }

                const endTimeMinute = timeToMinutes(endTime);
                const duration = endTimeMinute - startTimeMinute;
                const heightMultiplier = duration / 15;

                const formattedStartTime = convertMinutesToTime(startTimeMinute);
                const formattedEndTime = convertMinutesToTime(endTimeMinute);

                // Keep <section> to avoid div collision with css selector
                return (
                    <section
                        key={`time-slot-${day}-${timeSlotIndex}-${index}`}
                        className="calendar-time-slot-relative-container"
                        style={{
                            height: 0,
                            pointerEvents: draggingDay !== undefined ? 'none' : 'auto',
                        }}
                    >
                        <div
                            className="calendar-absolute-container"
                            style={{
                                height: `${TIME_SLOT_HEIGHT * heightMultiplier}px`,
                                pointerEvents: draggingDay !== undefined ? 'none' : 'auto',
                            }}
                        >
                            <div className="calendar-remove-x" hidden={!isEdit}>
                                <FaX size={12} onClick={() => handleTimeRemove(day, index)} />
                            </div>

                            <div className="calendar-time-slot p-2 text-xs sm:p-0 md:text-base">
                                <span>
                                    {formattedStartTime} - {formattedEndTime}
                                </span>
                            </div>
                        </div>
                    </section>
                );
            })}
        </>
    );
}
