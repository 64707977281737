import { useEffect, useState } from 'react';
import { getGeolocation } from '../../../utils/get-geolocation.util';
import { navigateToLocation, navigateToPlaces } from '../../../utils/route.utils';
import { Activity } from '../../../constants/enums';
import { NavigateFunction } from 'react-router';
import { Place } from '../../../constants/entities.types';
import { getPlaces } from '../../../api/place.api';
import { addDistanceAway, sortByDistanceAway } from '../../../utils/place.util';
import { Coordinates } from '../../../constants/types';

export const usePlacesReroute = (
    navigate: NavigateFunction,
    activity: Activity,
    latitude: number,
    longitude: number
) => {
    useEffect(() => {
        if (!latitude && !longitude) {
            getGeolocation(
                (latitude, longitude) => {
                    navigateToPlaces(navigate, activity, { latitude, longitude });
                },
                () => {
                    navigateToLocation(navigate, activity);
                }
            );
        }
    }, [latitude, longitude]);
};

type FetchPlacesPayload = {
    activity: Activity;
    longitude: number;
    latitude: number;
    maximumDistance: number;
    userCoords: Coordinates;
};

export const useFetchPlaces = ({
    activity,
    longitude,
    latitude,
    maximumDistance,
    userCoords,
}: FetchPlacesPayload) => {
    const [places, setPlaces] = useState<Place[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (places.length === 0) {
            getPlaces({ activity, latitude, longitude, maximumDistance })
                .then(({ places }) => {
                    if (places && Array.isArray(places)) {
                        const placesWithDistance = addDistanceAway(places, userCoords);
                        const sortedPlaces = sortByDistanceAway(placesWithDistance);
                        setPlaces(sortedPlaces);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [places.length, longitude, latitude]);

    return { isLoading, places };
};
