import { WEEKDAYS } from '../../../constants/constants';
import { OpenPlayTimesDTO } from '../../../constants/entities.types';
import { DayOfTheWeek } from '../../../constants/enums';
import {
    HandleMouseDownFn,
    HandleMouseEnterCellFn,
    HandleTimeRemoveFn,
    HandleTouchMoveFn,
    TIME_SLOTS,
} from '../calendar.constants';
import { EmptyCell } from './empty-cell';
import { TimeSlot } from './time-slot';

type TimeSlotsProps = {
    openPlayTimesDTO: OpenPlayTimesDTO;
    handleMouseEnterCell: HandleMouseEnterCellFn;
    handleMouseDown: HandleMouseDownFn;
    handleTouchStart: HandleMouseDownFn;
    draggingDay: DayOfTheWeek;
    handleTimeRemove: HandleTimeRemoveFn;
    isEdit: boolean;
};

export function TimeSlots({
    openPlayTimesDTO,
    isEdit,
    draggingDay,
    handleTimeRemove,

    handleMouseEnterCell,
    handleMouseDown,
    handleTouchStart,
}: TimeSlotsProps) {
    return (
        <>
            {WEEKDAYS.map((day) => {
                return (
                    <div key={`calendar-col-${day}`} className="calendar-col">
                        {TIME_SLOTS.map((index) => {
                            return (
                                <>
                                    <EmptyCell
                                        key={`calendar-empty-cell-${day}-${index}`}
                                        handleMouseDown={handleMouseDown}
                                        handleMouseEnterCell={handleMouseEnterCell}
                                        handleTouchStart={handleTouchStart}
                                        day={day}
                                        timeSlotIndex={index}
                                    />
                                    <TimeSlot
                                        key={`calendar-time-${day}-${index}`}
                                        draggingDay={draggingDay}
                                        day={day}
                                        timeSlotIndex={index}
                                        openPlayTimesDTO={openPlayTimesDTO}
                                        handleTimeRemove={handleTimeRemove}
                                        isEdit={isEdit}
                                    />
                                </>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
}
