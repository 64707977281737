import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { Footer } from './components/footer/footer';
import {
    ABOUT_ROUTE,
    ACTIVITY_SELECT_ROUTE,
    CONTACT_US_ROUTE,
    CREATE_PLACE_ROUTE,
    HISTORY_ROUTE,
    LOCATION_ROUTE,
    OPEN_PLAY_TIMES_ROUTE,
    PLACES_ROUTE,
    PRIVACY_POLICY_ROUTE,
    TERMS_AND_CONDITIONS_ROUTE,
} from './constants/routes';
import { ActivitySelect } from './views/activity-select/activity-select.view';
import { CreatePlaceView } from './views/create-place/create-place.view';
import { Header } from './components/header/header';
import { LocationView } from './views/location/location.view';
import { PlacesView } from './views/places/places.view';
import { Message } from './components/message/message';
import { GoogleProvider } from './components/google-map/google-provider';
import { useSetUserId } from './hooks/user-set-user-id.hook';
import { OpenPlayTimesView } from './views/open-play-times/open-play-times.view';
import { SiteNotice } from './components/site-notice/site-notice';
import { TermsAndConditions } from './views/t&c/t&c.view';
import { PrivacyPolicy } from './views/privacy-policy/privacy-policy.view';
import { ContactUs } from './views/contact-us/contact-us.view';
import { AboutView } from './views/about/about.view';
import { HistoryView } from './views/history/history.view';
import { HelmetProvider } from 'react-helmet-async';

export const Router = () => {
    useSetUserId();

    return (
        <GoogleProvider>
            <BrowserRouter>
                <HelmetProvider>
                    <div className="appContainer">
                        <Message />
                        <Header />
                        <SiteNotice message="LocalPlay.io Beta. Currently Under Construction." />
                        <div className="app">
                            <Routes>
                                <Route path="/" element={<Navigate to={ACTIVITY_SELECT_ROUTE} />} />
                                <Route path={ACTIVITY_SELECT_ROUTE} element={<ActivitySelect />} />
                                <Route path={LOCATION_ROUTE} element={<LocationView />} />
                                <Route path={CREATE_PLACE_ROUTE} element={<CreatePlaceView />} />
                                <Route path={PLACES_ROUTE} element={<PlacesView />} />
                                <Route
                                    path={OPEN_PLAY_TIMES_ROUTE}
                                    element={<OpenPlayTimesView />}
                                />
                                <Route path={HISTORY_ROUTE} element={<HistoryView />} />
                                <Route
                                    path={TERMS_AND_CONDITIONS_ROUTE}
                                    element={<TermsAndConditions />}
                                />
                                <Route path={PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
                                <Route path={CONTACT_US_ROUTE} element={<ContactUs />} />
                                <Route path={ABOUT_ROUTE} element={<AboutView />} />
                            </Routes>
                        </div>
                    </div>
                    <Footer />
                </HelmetProvider>
            </BrowserRouter>
        </GoogleProvider>
    );
};
