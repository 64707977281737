type SiteNoticeProps = {
    message: string;
};

export function SiteNotice({ message }: SiteNoticeProps) {
    return (
        <div className="mb-4 flex w-full max-w-max-app items-center justify-center rounded bg-cyan-800 p-1 px-12 font-medium">
            <div>{message}</div>
        </div>
    );
}
