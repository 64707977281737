import { Link } from 'react-router-dom';
import {
    CONTACT_US_ROUTE,
    DISCORD_ROUTE,
    PRIVACY_POLICY_ROUTE,
    TERMS_AND_CONDITIONS_ROUTE,
} from '../../constants/routes';
import './t&c.css';
import { View } from '../../components/view';

export function TermsAndConditions() {
    return (
        <View route={TERMS_AND_CONDITIONS_ROUTE} title="Terms And Conditions">
            <div className="terms-and-conditions">
                <div className="t-a-c-header">LocalPlay.io Terms and Conditions</div>
                <p>
                    By using this website and its services, you agree to comply with and be bound by
                    the following Terms and Conditions. If you do not agree to these terms, please
                    do not use this website.
                </p>
                <section>1. Acceptance of Terms</section>
                <p>
                    By accessing or using LocalPlay.io, you acknowledge that you have read,
                    understood, and agree to be bound by these Terms and Conditions. These terms may
                    be updated from time to time, and it is your responsibility to review them
                    periodically. Your continued use of the site after any updates constitutes
                    acceptance of the revised terms.
                </p>
                <section>2. Crowdsourced Data</section>
                <p>
                    All data on LocalPlay.io is crowdsourced, meaning users contribute information
                    such as locations and times where they play various sports (e.g., pickleball,
                    basketball). LocalPlay.io does not verify the accuracy or completeness of the
                    information submitted. By using this site, you understand that the data may be
                    incomplete, outdated, or incorrect. You are solely responsible for verifying any
                    information before relying on it.
                </p>
                <section>3. Anonymous Contributions</section>
                <p>
                    All user contributions are anonymous. No personally identifiable information is
                    required or stored when submitting data to the platform. However, we may collect
                    general usage data to improve the website's functionality. For more information,
                    please see our{' '}
                    <Link to={PRIVACY_POLICY_ROUTE} className="underline">
                        privacy policy.
                    </Link>
                </p>
                <section>4. Use of Information</section>
                <p>
                    You agree that any information you contribute to LocalPlay.io is made freely
                    available to the community. By submitting data, you grant LocalPlay.io a
                    perpetual, royalty-free, non-exclusive right to use, modify, distribute, and
                    display the information on the website and related platforms.
                </p>
                <section>5. Community Moderation</section>
                <p>
                    We encourage self-moderation through community voting on the accuracy and
                    relevance of submitted data (e.g., upvotes/downvotes for locations and time
                    slots). However, LocalPlay.io reserves the right to remove any data that is
                    inappropriate, inaccurate, or harmful. All decisions regarding the removal of
                    data are at the sole discretion of LocalPlay.io.
                </p>
                <section>6. Prohibited Conduct</section>
                <p>
                    When using LocalPlay.io, you agree not to: Post false, misleading, or inaccurate
                    information. Submit offensive, defamatory, or illegal content. Use the platform
                    for advertising or self-promotion. Engage in any activity that could harm the
                    website, its users, or the accuracy of the crowdsourced data.
                </p>
                <section>7. Liability and Disclaimers</section>
                <p>
                    LocalPlay.io provides the platform "as is" without warranties of any kind,
                    either express or implied. We make no guarantees regarding the accuracy,
                    reliability, or availability of the content provided. LocalPlay.io is not liable
                    for any damages or losses resulting from the use of information on this
                    platform, including physical injury, property damage, or any other losses.
                </p>
                <section>8. Third-Party Links</section>
                <p>
                    This website may contain links to third-party websites or services. These links
                    are provided for your convenience, and LocalPlay.io does not endorse or assume
                    responsibility for any third-party content or services.
                </p>
                <section>9. Termination of Use</section>
                <p>
                    LocalPlay.io reserves the right to suspend or terminate your access to the
                    platform at any time, for any reason, without prior notice.
                </p>
                <section>11. Contact Information</section>
                <p>
                    If you have any questions or concerns about these Terms and Conditions, please
                    contact us at through our discord found &nbsp;
                    <a className="underline" href={DISCORD_ROUTE}>
                        here
                    </a>
                    &nbsp; or through the &nbsp;
                    <Link className="underline" to={CONTACT_US_ROUTE}>
                        contact us form
                    </Link>
                </p>
                <section>12. Third-Party Advertising</section>
                <p>
                    We display third-party advertisements on this website through services like
                    Google AdSense. These ads may involve the use of cookies or web beacons to
                    collect information about users in order to serve personalized ads. By using
                    this site, you agree to the collection and use of your information by these
                    third parties in accordance with their respective privacy policies.
                </p>
            </div>
        </View>
    );
}
