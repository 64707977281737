import { MutableRefObject } from 'react';
import { WEEKDAYS, WEEKDAY_TO_ABR } from '../../../constants/constants';
import { HandleCopyFn } from '../calendar.constants';
import { OpenPlayTimesDTO } from '../../../constants/entities.types';
import { DayOfTheWeek } from '../../../constants/enums';
import { FaCopy } from 'react-icons/fa6';
import { Options } from '../../options/options';

type DayHeadersProps = {
    handleCopy: HandleCopyFn;
    openPlayTimesDTO: OpenPlayTimesDTO;
    isEdit: boolean;
};

export function DayHeaders({ handleCopy, openPlayTimesDTO, isEdit }: DayHeadersProps) {
    function createCopyList(currentDay: DayOfTheWeek) {
        const copyList: DayOfTheWeek[] = [];
        WEEKDAYS.forEach((day) => {
            if (openPlayTimesDTO?.[day]?.[0] && day !== currentDay) {
                copyList.push(day);
            }
        });
        return copyList;
    }

    return (
        <div className="calendar-row">
            {/* render empty for time series */}
            <div className="time-scale xs:hidden sm:block" />
            {WEEKDAYS.map((day) => {
                return (
                    <div key={`calendar-header-${day}`} className="calendar-day-header">
                        <span>{WEEKDAY_TO_ABR[day]}</span>
                        <Options
                            hidden={!isEdit}
                            itemKeyPrefix={`${day}-copy-list`}
                            customRender={
                                <div className="time-copy-icon ml-2 pt-2">
                                    <FaCopy size={12} />
                                </div>
                            }
                            options={createCopyList(day)}
                            onOptionSelected={(fromDay) => handleCopy(fromDay, day)}
                        />
                    </div>
                );
            })}
        </div>
    );
}
