import { FaCircle } from 'react-icons/fa6';
import * as routes from '../../../constants/routes';
import { Link } from 'react-router-dom';

const ROUTES_TO_LABELS = {
    [routes.TERMS_AND_CONDITIONS_ROUTE]: 'Terms and Conditions',
    [routes.PRIVACY_POLICY_ROUTE]: 'Privacy Policy',
    [routes.DISCORD_ROUTE]: 'Discord',
    [routes.PATREON_ROUTE]: 'Patreon',
    [routes.CONTACT_US_ROUTE]: 'Contact Us',
};

const ORDER = [
    routes.TERMS_AND_CONDITIONS_ROUTE,
    routes.PRIVACY_POLICY_ROUTE,
    routes.CONTACT_US_ROUTE,
    routes.DISCORD_ROUTE,
    routes.PATREON_ROUTE,
];

export function FooterLinks() {
    function renderLinks() {
        return ORDER.map((route, index) => {
            const label = ROUTES_TO_LABELS[route];
            const hasNext = Boolean(ORDER[index + 1]);

            return (
                <div key={`footer-${route}`} className="flex flex-row flex-wrap items-center">
                    <Link className="mr-2 underline" to={route}>
                        {label}
                    </Link>
                    {hasNext && <FaCircle size={4} className="mr-2 xs:hidden md:inline-block" />}
                </div>
            );
        });
    }

    return (
        <div className="flex w-full flex-row justify-end xs:flex-col xs:items-end md:flex-row md:items-center">
            {renderLinks()}
        </div>
    );
}
