import { Logo } from '../../components/logo/logo';
import { Activity } from '../../constants/enums';
import { navigateToLocation } from '../../utils/route.utils';
import { useNavigate } from 'react-router-dom';
import { Options } from '../../components/options/options';
import { ACTIVITY_SELECT_ROUTE } from '../../constants/routes';
import { View } from '../../components/view';

export function ActivitySelect() {
    const navigate = useNavigate();

    const handleActivitySelected = (activity) => {
        navigateToLocation(navigate, activity);
    };

    return (
        <View route={ACTIVITY_SELECT_ROUTE} title="Select Activity">
            <Logo>
                <div className="flex w-full justify-center">
                    <Options
                        className="w-full max-w-48"
                        itemKeyPrefix="ActivityOption"
                        label="Select an activity"
                        options={Object.values(Activity)}
                        onOptionSelected={handleActivitySelected}
                    />
                </div>
            </Logo>
        </View>
    );
}
