import dayjs from 'dayjs';
import { FooterLinks } from './components/footer-links';

export const Footer = () => {
    return (
        <div className="flex w-full items-center justify-center border-t-2 bg-background-color">
            <div className="flex w-full max-w-max-app flex-wrap p-4 text-dark-text-color xs:flex-col sm:flex-row">
                <div>&copy; {dayjs().year()} OkayestDev</div>
                <div className="flex-1">
                    <FooterLinks />
                </div>
            </div>
        </div>
    );
};
