import { Helmet } from 'react-helmet-async';

export function View({ children, route, title }) {
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <link rel="canonical" href={route} />
            </Helmet>
            {children}
        </>
    );
}
