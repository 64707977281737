import { useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonClassName } from '../../components/button/button';
import { Divider } from '../../components/divider/divider';
import { getGeolocation } from '../../utils/get-geolocation.util';
import { useReroute } from '../../hooks/use-reroute.hook';
import { caseInsensitiveLookup } from '../../utils/object.util';
import { Activity } from '../../constants/enums';
import { ACTIVITY_SELECT_ROUTE, LOCATION_ROUTE } from '../../constants/routes';
import { navigateToPlaces, to } from '../../utils/route.utils';
import { Logo } from '../../components/logo/logo';
import { PlaceAutocomplete } from '../../components/google-map/place-autocomplete';
import { PlaceResult } from '../../constants/types';
import { View } from '../../components/view';

export function LocationView() {
    const navigate = useNavigate();

    const { activity } = useParams();

    useReroute(to(ACTIVITY_SELECT_ROUTE), !Boolean(caseInsensitiveLookup(Activity, activity)));

    function coordinatesCallback(latitude: number, longitude: number) {
        navigateToPlaces(navigate, activity, { latitude, longitude });
    }

    // @todo
    function errorCallback(error) {}

    function handleUseMyLocationPressed() {
        getGeolocation(coordinatesCallback, errorCallback);
    }

    function handlePlaceSelected(place: PlaceResult) {
        coordinatesCallback(place.geometry.location.lat(), place.geometry.location.lng());
    }

    return (
        <View route={LOCATION_ROUTE} title="Select Location">
            <div className="flex w-96 flex-col items-center">
                <Logo className="flex w-full flex-col items-center justify-center">
                    <PlaceAutocomplete
                        onPlaceSelect={handlePlaceSelected}
                        placeholder="Your Address"
                        className="w-64 sm:w-96"
                        fields={['geometry', 'name']}
                    />
                    <Divider label="or" className="w-52" />
                    <Button
                        className={ButtonClassName.Primary}
                        onClick={handleUseMyLocationPressed}
                    >
                        Use My Location
                    </Button>
                </Logo>
            </div>
        </View>
    );
}
