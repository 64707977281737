import { Button, ButtonClassName } from '../../../components/button/button';
import { EventFunction } from '../../../constants/types';

type HeaderActionProps = {
    handleEditPressed: EventFunction;
    handleSubmitPressed: EventFunction;
    handleCancelClicked: EventFunction;
    isLoadingUpdate: boolean;
    isEdit: boolean;
};

export function HeaderAction({
    handleEditPressed,
    handleSubmitPressed,
    handleCancelClicked,
    isLoadingUpdate,
    isEdit,
}: HeaderActionProps) {
    if (!isEdit) {
        return (
            <Button className={ButtonClassName.Secondary} onClick={handleEditPressed}>
                Edit
            </Button>
        );
    }

    return (
        <div className="flex flex-row">
            <Button className={`${ButtonClassName.Secondary} mr-4`} onClick={handleCancelClicked}>
                Cancel
            </Button>
            <Button
                className={ButtonClassName.Primary}
                onClick={handleSubmitPressed}
                isLoading={isLoadingUpdate}
            >
                Update
            </Button>
        </div>
    );
}
