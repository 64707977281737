import { useState } from 'react';
import { useParams } from 'react-router';
import { SelectAddress } from './components/select-address';
import { AddInformation } from './components/add-information';
import { PlaceResult } from '../../constants/types';
import { useBoolean } from '../../hooks/use-boolean.hook';
import { OpenPlayTimesDTO, PlaceDTO } from '../../constants/entities.types';
import { Activity } from '../../constants/enums';
import {
    grabPlaceResultFields,
    resolveCostTypes,
    resolveFacilityTypes,
} from '../../utils/place.util';
import { Button, ButtonClassName } from '../../components/button/button';
import { Tooltip } from '../../components/tooltip/tooltip';
import { Card } from '../../components/card/card';
import { useQueryCoords } from '../../hooks/use-query-coords.hook';
import { Calendar } from '../../components/calendar/calendar';
import { useCreatePlace } from './hooks/use-create-place.hook';
import { View } from '../../components/view';
import { CREATE_PLACE_ROUTE } from '../../constants/routes';

export function CreatePlaceView() {
    const { activity } = useParams();

    const { latitude, longitude } = useQueryCoords();

    const [selectedPlace, setSelectedPlace] = useState<PlaceResult>(undefined);

    const [step, setStep] = useState(0);

    const [facilityCount, setFacilityCount] = useState(1);
    const [openPlayTimes, setOpenPlayTimes] = useState<OpenPlayTimesDTO>({});

    const [isFree, toggleIsFree] = useBoolean(false);
    const [isOutside, toggleIsOutside] = useBoolean(false);
    const [isPaid, toggleIsPaid] = useBoolean(false);
    const [isInside, toggleIsInside] = useBoolean(false);

    const { isLoading, createPlace } = useCreatePlace();

    function handleNextPressed() {
        setStep((step) => step + 1);
    }

    function handleSubmitPressed() {
        createPlace(
            {
                facilityCount,
                facilityTypes: resolveFacilityTypes(isOutside, isInside),
                costTypes: resolveCostTypes(isFree, isPaid),
                activity: activity as Activity,
                ...grabPlaceResultFields(selectedPlace),
            } as PlaceDTO,
            openPlayTimes,
            activity as Activity
        );
    }

    function renderStep() {
        if (step === 0) {
            return (
                <SelectAddress
                    selectedPlace={selectedPlace}
                    setSelectedPlace={setSelectedPlace}
                    handleNextPressed={handleNextPressed}
                    defaultLat={latitude}
                    defaultLng={longitude}
                />
            );
        }

        if (step === 1) {
            return (
                <AddInformation
                    activity={activity as Activity}
                    isFree={isFree}
                    toggleIsFree={toggleIsFree}
                    facilityCount={facilityCount}
                    setFacilityCount={setFacilityCount}
                    isInside={isInside}
                    toggleIsInside={toggleIsInside}
                    selectedPlace={selectedPlace}
                    handleNextPressed={handleNextPressed}
                    isPaid={isPaid}
                    toggleIsPaid={toggleIsPaid}
                    isOutside={isOutside}
                    toggleIsOutside={toggleIsOutside}
                />
            );
        }

        if (step === 2) {
            return (
                <Card
                    header={
                        <span>
                            Open Play Hours{' '}
                            <Tooltip
                                tooltip="When do you play here?"
                                tooltipTarget="openPlayHours"
                            />
                        </span>
                    }
                    headerAction={
                        <Button
                            className={ButtonClassName.Primary}
                            onClick={handleSubmitPressed}
                            isLoading={isLoading}
                        >
                            Submit
                        </Button>
                    }
                >
                    <Calendar
                        openPlayTimesDTO={openPlayTimes}
                        setOpenPlayTimes={setOpenPlayTimes}
                        isEdit={true}
                    />
                </Card>
            );
        }
    }

    return (
        <View route={CREATE_PLACE_ROUTE} title="Create Place">
            {renderStep()}
        </View>
    );
}
