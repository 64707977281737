import { Card } from '../../../components/card/card';
import { History } from '../../../constants/entities.types';
import { HistoryType } from '../../../constants/enums';
import { timestampToFormatted } from '../../../utils/date.util';

type HistoryProps = {
    history: History;
};

const HISTORY_TYPE_TO_CLASSNAME = {
    [HistoryType.Addition]: 'text-green-600',
    [HistoryType.Change]: 'text-yellow-500',
};

export function HistoryComponent({ history }: HistoryProps) {
    const historyTypeClassName = HISTORY_TYPE_TO_CLASSNAME[history.pk];

    return (
        <Card className="mb-2 p-4 px-4" key={history.sk}>
            <div className="flex flex-row flex-wrap">
                <div className={`mr-8 w-20 ${historyTypeClassName}`}>{history.pk}</div>
                <div className="mr-8">{history.description}</div>
                <div className="flex flex-1 justify-end">
                    {timestampToFormatted(history.createdAt)}
                </div>
            </div>
        </Card>
    );
}
