import { useParams } from 'react-router';
import { useFetchOpenPlayTimes } from './hooks/use-fetch-open-play-times.hook';
import { LoadingEntities } from '../../components/loading-entities/loading-entities';
import { toTitleCase } from '../../utils/string.util';
import { Card } from '../../components/card/card';
import { toDTO } from '../../utils/open-play-times.util';
import { useState, useEffect } from 'react';
import { OpenPlayTimesDTO } from '../../constants/entities.types';
import { Calendar } from '../../components/calendar/calendar';
import { HeaderAction } from './components/header-action';
import { useUpdateOpenPlayTimes } from './hooks/use-update-open-play-times.hook';
import { deepCopy } from '../../utils/object.util';
import { View } from '../../components/view';
import { OPEN_PLAY_TIMES_ROUTE } from '../../constants/routes';

export function OpenPlayTimesView() {
    const { placeId } = useParams();

    const [isEdit, setIsEdit] = useState(false);

    const {
        place,
        openPlayTimes,
        isLoading: isOpenPlayTimesLoading,
    } = useFetchOpenPlayTimes({ placeId });

    const [openPlayTimesDTO, setOpenPlayTimesDTO] = useState<OpenPlayTimesDTO>({});
    const [openPlayTimesDTOEditCopy, setOpenPlayTimesDTOEditCopy] = useState<OpenPlayTimesDTO>({});

    const { isLoading: isLoadingUpdate, updateOpenPlayTimes } = useUpdateOpenPlayTimes();

    useEffect(() => {
        setOpenPlayTimesDTO(toDTO(openPlayTimes));
    }, [openPlayTimes?.length]);

    function handleEditPressed() {
        setIsEdit(true);
        setOpenPlayTimesDTOEditCopy(deepCopy(openPlayTimesDTO));
    }

    function handleSubmitPressed() {
        updateOpenPlayTimes(placeId, place.pk, openPlayTimesDTO).then(() => {
            setIsEdit(false);
        });
    }

    function handleCancelClicked() {
        setOpenPlayTimesDTO(openPlayTimesDTOEditCopy);
        setIsEdit(false);
    }

    function renderOpenPlayTimes() {
        if (isOpenPlayTimesLoading) {
            return <LoadingEntities label="Loading Open Play Times" />;
        }

        const { pk: activity, name } = place;

        return (
            <Card
                header={`${toTitleCase(activity)} at ${name}`}
                headerAction={
                    <HeaderAction
                        handleCancelClicked={handleCancelClicked}
                        isEdit={isEdit}
                        handleEditPressed={handleEditPressed}
                        handleSubmitPressed={handleSubmitPressed}
                        isLoadingUpdate={isLoadingUpdate}
                    />
                }
            >
                <Calendar
                    openPlayTimesDTO={openPlayTimesDTO}
                    isEdit={isEdit}
                    setOpenPlayTimes={setOpenPlayTimesDTO}
                />
            </Card>
        );
    }

    return (
        <View route={OPEN_PLAY_TIMES_ROUTE} title="Open Play Times">
            <div className="w-full">{renderOpenPlayTimes()}</div>
        </View>
    );
}
