export function caseInsensitiveLookup(obj: object, key: string): any | undefined {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
        if (key.toLowerCase() === keys[i].toLowerCase()) {
            return keys[i];
        }
    }
    return undefined;
}

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
