import { DayOfTheWeek } from '../../constants/enums';

export const TIME_SLOTS = Array.from({ length: 96 }, (_, i) => i); // 96 15 mins slots per day
export const TIME_SLOT_MINUTES = Array.from({ length: 96 }, (_, i) => i * 15);
export const TIME_SLOT_HEIGHT = 15;

export type HandleMouseEnterCellFn = (day: DayOfTheWeek, timeSlotIndex: number) => any;

export type HandleMouseDownFn = (day: DayOfTheWeek, timeSlotIndex: number) => any;

export type HandleTimeRemoveFn = (day: DayOfTheWeek, index: number) => any;

export type HandleCopyFn = (fromDay: DayOfTheWeek, toDay: DayOfTheWeek) => any;

export type HandleTouchMoveFn = (
    event: React.TouchEvent<HTMLDivElement>,
    day: DayOfTheWeek,
    timeSlotIndex: number
) => any;
