import { useState } from 'react';
import { Card } from '../../components/card/card';
import { Input } from '../../components/input';
import { Button, ButtonClassName } from '../../components/button/button';
import { Textarea } from '../../components/textarea';
import { contactUs } from '../../api/contact-us.api';
import { messageActions } from '../../stores/message.store';
import { useDispatch } from 'react-redux';
import { MessageType } from '../../constants/enums';
import { CONTACT_US_ROUTE } from '../../constants/routes';
import { View } from '../../components/view';

export function ContactUs() {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [text, setText] = useState('');

    function handleSubmitPressed() {
        contactUs({ email, name, text }).then(() => {
            dispatch(
                messageActions.setMessage({
                    message: 'Submitted form.',
                    messageType: MessageType.Success,
                })
            );
            setEmail('');
            setName('');
            setText('');
        });
    }

    return (
        <View route={CONTACT_US_ROUTE} title="Contact Us">
            <div className="max-w-1/3 w-full">
                <Card
                    header="Contact Us"
                    headerAction={
                        <Button
                            className={ButtonClassName.Primary}
                            onClick={handleSubmitPressed}
                            disabled={!(email && name && text)}
                        >
                            Submit
                        </Button>
                    }
                >
                    <div className="mb-4 flex flex-row">
                        <div className="mr-12 w-1/2">
                            <div className="mb-2 text-lg">Name</div>
                            <Input value={name} handleOnChange={setName} />
                        </div>
                        <div className="w-1/2">
                            <div className="mb-2 text-lg">Email</div>
                            <Input value={email} handleOnChange={setEmail} />
                        </div>
                    </div>
                    <div>
                        <div className="mb-2 text-lg">Inquiry</div>
                        <Textarea
                            value={text}
                            aria-multiline={true}
                            onChange={(val) => setText(val.target.value)}
                        />
                    </div>
                </Card>
            </div>
        </View>
    );
}
