import { Place } from '../../../../constants/entities.types';
import { searchString } from '../../../../utils/string.util';
import { NoPlaces } from '../no-places';
import { PlaceComponent } from './place-component';

type PlacesProps = {
    places: Place[];
    latitude: number;
    longitude: number;
    searchText: string;
};

export function Places({ places, latitude, longitude, searchText }: PlacesProps) {
    function renderPlaces() {
        if (!places || places.length === 0) {
            return <NoPlaces />;
        }

        return places.map((place, index) => {
            // @todo add free/paid inside/outside searching to text?
            const isInSearch = searchString(searchText, [place.name, place.address]);

            if (!isInSearch) {
                return undefined;
            }

            return (
                <PlaceComponent
                    key={`${place}-${index}`}
                    place={place}
                    latitude={latitude}
                    longitude={longitude}
                />
            );
        });
    }

    return <div>{renderPlaces()}</div>;
}
