import { configureStore } from '@reduxjs/toolkit';
import { localPlayStore } from './localplay.store';
import { MESSAGE_STORE, LOCAL_PLAY_STORE } from '../constants/constants';
import { messageMiddleware } from '../middlewares/message.middleware';
import { messageStore } from './message.store';
import { loggerMiddleware } from '../middlewares/logger.middleware';
import { log } from '../utils/local-logger.util';

const LOCAL_STORAGE_KEY = 'localplayio';

export type RootState = {
    [LOCAL_PLAY_STORE]: ReturnType<typeof localPlayStore.reducer>;
    [MESSAGE_STORE]: ReturnType<typeof messageStore.reducer>;
};

// Load state from localStorage
const loadState = (): RootState | undefined => {
    try {
        const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (serializedState === null) {
            log('Found no state');
            return undefined;
        }
        const parsedState = JSON.parse(serializedState);
        log('Hydrating', parsedState);
        return parsedState;
    } catch (err) {
        return undefined;
    }
};

// Save state to localStorage
const saveState = (state: RootState) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
    } catch (err) {
        console.error('Failed to save state');
    }
};

export const store = configureStore({
    reducer: {
        [LOCAL_PLAY_STORE]: localPlayStore.reducer,
        [MESSAGE_STORE]: messageStore.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(messageMiddleware).concat(loggerMiddleware),
    preloadedState: loadState(),
});

// Subscribe to store updates to save the state
store.subscribe(() => {
    saveState(store.getState());
});
