import { Card } from '../../../../components/card/card';
import { Place } from '../../../../constants/entities.types';
import { getDistanceAway } from '../../../../utils/place.util';
import { Button, ButtonClassName } from '../../../../components/button/button';
import { navigateToOpenPlayTimes } from '../../../../utils/route.utils';
import { useNavigate } from 'react-router';
import { OpenPlayDays } from './open-play-days';
import { PlaceExpansion } from './place-expansion';
import { useBoolean } from '../../../../hooks/use-boolean.hook';
import { OpenCloseToggle } from '../../../../components/open-close-toggle';

type PlaceProps = {
    place: Place;
    latitude: number;
    longitude: number;
};

export function PlaceComponent({ place, latitude, longitude }: PlaceProps) {
    const navigate = useNavigate();
    const [isExpanded, toggleIsExpanded] = useBoolean(false);

    const distanceAway = getDistanceAway(place, { latitude, longitude });
    const { costTypes, facilityTypes, name, openPlayDays, address, googleUrl } = place;

    function handleOpenPlayTimesPressed() {
        navigateToOpenPlayTimes(navigate, place);
    }

    return (
        <Card className="mb-4">
            <div
                className="align-items flex w-full flex-row flex-wrap p-1"
                // onClick={toggleIsExpanded}
            >
                <section className="flex flex-col xs:mb-4 md:mb-0">
                    <h1 className="text-xl font-medium">{name}</h1>
                    <div>
                        <a
                            className="underline hover:brightness-75"
                            href={googleUrl}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {address}
                        </a>
                    </div>
                    <div className="flex flex-row text-dark-text-color">
                        <div className="mr-4">{distanceAway.toFixed(1)} miles away</div>
                        <div className="mr-4">{costTypes.join(' and ')}</div>
                        <div className="mr-4">{facilityTypes.join(' and ')}</div>
                    </div>
                </section>
                <section className="flex flex-1 flex-row flex-wrap items-center justify-end">
                    {/* <Vote
                        upVotes={upVotes}
                        downVotes={downVotes}
                        onUpVote={() => {}}
                        onDownVote={() => {}}
                    /> */}
                    <div className="xs:mb-4 md:mb-0">
                        <OpenPlayDays openPlayDays={openPlayDays} />
                    </div>
                    <Button
                        className={`${ButtonClassName.Primary} ml-8`}
                        onClick={handleOpenPlayTimesPressed}
                    >
                        See Times
                    </Button>
                    {/* <div className="ml-2">
                        <OpenCloseToggle isOpen={isExpanded} size={18} />
                    </div> */}
                </section>
            </div>
            {/* {isExpanded && <PlaceExpansion place={place} />} */}
        </Card>
    );
}
